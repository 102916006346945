body{
  background-color: #F9FCFF;
}
.hide-on-mobile{
  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.hide-on-desktop{
  display: none;
  @media screen and (max-width: 1024px) {
    display: inline-block;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: transparent;
  text-transform: uppercase;
}

#myBtn:hover {
  background-color: transparent;
}

.dropdown-menu-left-auto {
  left: auto;
}

.button-list-menu {
  padding: 10px;
  border: none;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #1FA409;
  color: #1FA409;
  transition: 0.2s;
  text-decoration: none !important;

  &:hover {
    color: #FFFFFF;
    background-color: #1FA409;
  }
}

.navbar-custom{
  padding: 5px 80px;
  transition: all 0.5s;
  .navbar-brand{
    a{
      img{
        height: 35px !important;
        margin-left: 5px;
      }
    }
  }
  .navbar-collapse{
    .navbar-nav{
      .nav-tko-dropdown{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
      }
      .nav-tko-drop-link{
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        img{
          margin-right: 5px;
        }
        span{
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #FFFFFF;
        }
      }
    }
    .nav-horizontal{
      margin-right: 100px;
      .nav-tko-top-link{
        font-family:Poppins;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        margin-right: 20px;
        filter: drop-shadow(0 0 0.10rem rgb(138, 138, 138));
      }
      .nav-tko-top-link:hover{
        cursor: pointer;
        color: aquamarine;
      }
    }
    
    .mobile-munculeh {
      .nav-item {
        display: none !important;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    padding: 5px 10px;
  }
}
.navbar-custom-active {
  padding: 5px 80px;
  background-color: #fff;
  transition: all 0.5s;
  .navbar-brand {
    a {
      img {
        height: 35px !important;
        margin-left: 5px;
      }
    }
  }
  .navbar-collapse{
    .navbar-nav{
      .nav-tko-drop-link {
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #242424a1;
        img{
          margin-right: 5px;
        }
        span{
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #242424a1;
        }
      }
    }
    .mobile-munculeh {
      .nav-item {
        display: none;
      }
    }
    .nav-horizontal{
      margin-right: 100px;
      .nav-tko-top-link{
        font-family:Poppins;
        color: rgb(112, 112, 112);
        font-weight: 600;
        font-size: 14px;
        margin-right: 20px;
      }
      .nav-tko-top-link:hover{
        cursor: pointer;
        color: aquamarine;
      }
    }
    // .hilangpasmobile {
    //   display: none;
    // }
  }

  @media screen and (max-width: 1024px) {
    padding: 5px 10px;
    .navbar-collapse {
      .navbar-nav {
        .nav-tko-drop-link {
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;

          img {
            margin-right: 5px;
          }

          span {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #FFFFFF;
          }
        }
      }          
      .nav-item-parent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        .nav-item-symbol {
          font-size: 1.5rem;
          color: #122541;
        }
      }
      .nav-item-child {
        background-color: #e8e8e8;
        cursor: pointer;
      }
      .mobile-munculeh {
        padding-top: 20px;
        .nav-item {
          display: inline-block;
          .nav-link{
            padding: 20px 5px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.3px;
            color: #122541;
            border-bottom: 1px solid #DEDEDE;
          }
          .sosmed-nav {
            display: flex;
            flex-direction: column;
            .group-icon {
              padding-bottom: 10px;
              display: flex;
              margin-top: 15px;
              img{
                margin-right: 20px;
              }
            }
          }
          .nav-langheh{
            margin-top: 5px;
            display: flex;
            border-bottom: 0px solid transparent;
            .lang-heh{
              margin-right: 20px;
              img{
                margin-top: -3px;
              }
              span{
                margin-left: 5px;
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.3px;
                color: #000000;
              }
            }
          }
        }
      }
      .hilangpasmobile{
        display: none;
      }
    }
  }
}

.header-top {
  filter: brightness(70%);
}

.content-home{
  .frame-slide-h1 {
    overflow: hidden !important;
    .slick-slide{
      div {
        width: 100% !important;
      }
    }
    img {
      object-fit: cover;
      object-position: center;
      position: relative;
      height: 125vh;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100% !important;
    }
  }
  .header-1{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .frame-header-1{
      margin-top: 50px;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0px 100px;
      top: 0;

      .title-h1{
        font-family: Poppins;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: 72px;
        letter-spacing: 0.30000001192092896px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 6px 4px rgba(1, 37, 130, 0.25);
      }
      .sub-title-h1{
        font-family: Poppins;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 72px;
        letter-spacing: 0.30000001192092896px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 6px 4px rgba(1, 37, 130, 0.25);
      } 
      .sub-desc {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.30000001192092896px;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 6px 4px rgba(1, 37, 130, 0.25);
      }
      .button-group-h1{
        margin-top: 40px;
        .btn-buy{
          padding: 10px 15px;
          background: #01DE4C;
          box-shadow: 0px 12px 20px rgba(39, 39, 39, 0.25);
          border-radius: 12px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #FFFFFF;
        }
        .btn-liteh1 {
          padding: 10px 15px;
          border: 1px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 12px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #FFFFFF;
          margin-left: 20px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .frame-header-1 {
        margin-top: 0;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 40px;
        top: 0;

        .title-h1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 34px;
          letter-spacing: 0.30000001192092896px;
          text-align: center;
          color: #FFFFFF;
          text-shadow: 0px 6px 4px rgba(1, 37, 130, 0.25);
        }

        .sub-title-h1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.30000001192092896px;
          text-align: center;
          color: #FFFFFF;
          text-shadow: 0px 6px 4px rgba(1, 37, 130, 0.25);
        }
        .sub-desc {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          letter-spacing: 0.30000001192092896px;
          text-align: center;
          color: #FFFFFF;
          text-shadow: 0px 6px 4px rgba(1, 37, 130, 0.25);
        }

        .button-group-h1 {
          margin-top: 40px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .btn-buy {
            width: 100%;
            padding: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            background: #01DE4C;
            box-shadow: 0px 12px 20px rgba(39, 39, 39, 0.25);
            border-radius: 12px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #FFFFFF;
            margin-bottom: 10px;
          }

          .btn-liteh1 {
            width: 100%;
            padding: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            border-radius: 12px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #FFFFFF;
            margin-left: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .header-2 {
    margin-top: 30px;
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    margin-bottom: 30px;
    .frame-slidesshow-h2{
      padding: 20px 250px;
      .img-slideshow{
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
      .slick-dots{
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        ul{
          width: 99%;
          li {
            display: none;
            button {
              &::before {
                border-radius: 50%;
                color: #17A84B !important;
                font-size: 12px;
                // background-color: #17A84B !important;
              }
            }
          }
        }
        .button-lebar {
          width: 72%;
          li{
            display: inline-block;
          }
        }
        button{
          background-color: transparent;
          border: none;
          outline: none;
          &:active{
            border: none;
            outline: none;
          }
          @media screen and (max-width: 1024px) {
            display: none;
          }
        }
      }
    }
    .frame-lebar{
      padding: 30px 100px;
      transition: all 0.5;
    }
    .frame-detail-h2{
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .centered-detail {
        display: flex;
        // justify-content: center;
        // align-items: center;
        width: 60%;
        .divider{
          width: 4px;
          height: 247px;
          left: 553px;
          top: 117px;
          background: #077FA2;
          opacity: 0.3;
          border-radius: 50px;
        }
        .item-detail-h2{
          display: flex;
          margin-top: 8%;
          // justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          width: 33.3%;
          height: 100%;
          text-align: center;
          .title{
            // border-top: 1px solid black;
            display: flex;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 56px;
            line-height: 56px;
            letter-spacing: 0.3px;
            color: #122541;
            span{
              color: #E2EAE8;
            }
          }
          .sub-title {
            // border-top: 1px solid black;
            width: 50%;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #4A4A4A;
            span {
              color: #E2EAE8;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      margin-top: 0px;
      background-color: #F9FFFB;
      height: 100%;
      width: 100%;
      margin-bottom: 50px;

      .frame-slidesshow-h2 {
        padding: 0px 25px;
        .img-slideshow {
          width: 100%;
          height: 200px !important;
          object-fit: cover;
          border-radius: 16px !important;
        }
      }

      .frame-detail-h2 {
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .centered-detail {
          display: flex;
          // justify-content: center;
          // align-items: center;
          width: 100%;

          .divider {
            display: none;
            visibility: none;
          }

          .item-detail-h2 {
            display: flex;
            margin-top: 0%;
            // justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            width: 33.3%;
            height: 100%;
            text-align: center;

            .title {
              // border-top: 1px solid black;
              display: flex;
              font-family: Poppins;
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              letter-spacing: 0.3px;
              color: #122541;

              span {
                color: #E2EAE8;
              }
            }

            .sub-title {
              // border-top: 1px solid black;
              width: 100%;
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              letter-spacing: 0.3px;
              color: #4A4A4A;

              span {
                color: #E2EAE8;
              }
            }
          }
        }
      }
    }
  }
  .header-3 {
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 50px 50px;
    padding-bottom: 50px;
    .img-h3{
      width: 65%;
      height: 100%;
      margin-left: 50px;
    }
    .detail-h3 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .frame-h-h3{
        margin-top: -18%;
        margin-right: 20px;
        width: 50%;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(40px);
        border-radius: 16px;
        border-radius: 5px;
        color: #333;
        font-family: sans-serif;
        line-height: 1.5;
        padding: 2em;
        .title-h3 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 45px;
          line-height: 64px;
          letter-spacing: 0.3px;
          color: #122541;
          margin-bottom: 30px;
        }
  
        .desc-h3 {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 36px;
          letter-spacing: 0.3px;
          color: #4A4A4A;
          margin-bottom: 10px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 50px 20px;
      .img-h3 {
        width: 100%;
        height: 100%;
        margin-left: 0px;
      }

      .detail-h3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .frame-h-h3 {
          margin-top: 30px;
          margin-right: 0px;
          width: 100%;
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(40px);
          border-radius: 16px;
          border-radius: 5px;
          color: #333;
          font-family: sans-serif;
          line-height: 1.5;
          padding: 0.5em 1em;

          .title-h3 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.3px;
            color: #122541;
            margin-bottom: 30px;
          }

          .desc-h3 {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.3px;
            color: #4A4A4A;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .header-tabs {
    display: none;
    background: #F9FCFF;
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    margin: 70px auto;
    width: 85%;
    .slick-dots li button:before {
      font-size: 10px;
      color: #077FA2;
    }
    .item-tablist{
      margin-bottom: 10px;
      display: flex;
      width: 60%;
      text-align: center;
      outline: none;
      .desc-item-tab{
        margin-top: 20px;;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #4A4A4A;
      }
      .title-item-tab{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #122541;
        margin-bottom: 20px;
      }
    }
    img.img-tab{
      margin: 0 auto;
      display: block;
      width: 100%;
    }
    @media screen and (min-width: 500px){
      display:none;
    }
  }
  .header-3-v2 {
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 150px;
    margin-top: 70px;
    margin-bottom: 70px;
    .frame-tab-h3v2 {
      display: flex;
      justify-content: center;
      align-items: center;

      .item-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 365px;
        height: 86px;
        border-bottom: 1px solid #CECECE;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #122541;
      }

      .active-tab {
        border-bottom: 4px solid #17A84B;
        background: rgba(142, 223, 171, 0.1);
        border-radius: 16px 16px 0px 0px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #17A84B;
      }
    }

    .content-tab {
      .item-content-tab {
        margin-top: 5px;
        display: none;
        text-align: center;
        .title-item-content{
          display: none;
        }
        .desc-item-content {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 180%;
          letter-spacing: 0.3px;
          height: 180px;
          padding: 30px 130px;
          overflow-y: scroll;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background: #7BB0FF;
            border-radius: 50px;
          }
        }
        .img-h3-mobile{
          display: none;
        }

        .frame-image {
          display: flex;
          justify-content: space-between;
          align-content: center;

          .frame-button {
            display: flex;
            align-items: center;

            .arrow {
              width: 64px;
              height: 64px;
              border-radius: 50%;
              opacity: 0.2;
              background: #FFFFFF;
              box-shadow: 0px 20px 40px rgba(48, 93, 255, 0.08);
              transform: matrix(-1, 0, 0, 1, 0, 0);
              border: none;

              &:active {
                outline: none;
              }

              &:focus {
                outline: none;
              }
            }

            .arrow-active {
              opacity: 1;
            }
          }
        }

        .img-h3 {
          height: 370px;
          object-fit: cover;
          border-radius: 32px;
        }
      }

      .active-tab {
        display: inline-block;
      }
    }

    .dots-content {
      display: flex;
      justify-content: center;
      align-content: center;
      margin-top: 20px;

      .dots {
        width: 12px;
        height: 12px;
        background: #17A84B;
        opacity: 0.2;
        margin-right: 10px;
        border-radius: 50%;
        cursor: pointer;

        &:nth-last-child(n) {
          margin-right: 0px;
        }
      }

      .dots-acitve {
        opacity: 1;
      }
      .arrow {
        display: none !important;
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 10px 0px;
      margin-top: 30px;
      margin-bottom: 30px;
      .frame-tab-h3v2 {
        display: none;
      }

      .content-tab {
        .item-content-tab {
          margin-top: 5px;
          display: none;
          text-align: center;
          padding: 10px;
          .title-item-content{
            padding: 0px 20px;
            display: inline-block;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #122541;
          }
          .desc-item-content {
            text-align: left;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.3px;
            height: 250px;
            padding: 30px 20px;
            padding-top: 0px;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              width: 4px;
            }

            &::-webkit-scrollbar-track {
              background: #E0EDFF;
            }

            &::-webkit-scrollbar-thumb {
              background: #7BB0FF;
              border-radius: 50px;
            }
          }
          .img-h3-mobile {
            padding: 20px;
            display: inline-block;
            width: 100%;
            height: 240px;
            object-fit: cover;
            border-radius: 32px;
          }
          .frame-image {
            display: none;
          }
        }

        .active-tab {
          display: inline-block;
        }
      }

      .dots-content {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-top: 20px;

        .dots {
          margin-left: 1px;
          margin-top: 8px;
          width: 12px;
          height: 12px;
          background: #17A84B;
          opacity: 0.2;
          margin-right: 10px;
          border-radius: 50%;
          cursor: pointer;
          
          &:nth-last-child(n) {
            margin-right: 0px;
          }
        }

        .dots-acitve {
          opacity: 1;
        }
        .arrow {
          display: inline-block !important;
          width: 34px;
          height: 34px;
          border-radius: 50%;
          opacity: 0.2;
          background: transparent;
          box-shadow: 0px 20px 40px rgba(48, 93, 255, 0.08);
          transform: matrix(-1, 0, 0, 1, 0, 0);
          border: none;

          &:active {
            outline: none;
          }

          &:focus {
            outline: none;
          }
        }

        .left{
          margin-right: 10px;
        }
        .right{
          margin-left: -10px;
        }

        .arrow-active {
          opacity: 1;
        }
      }
    }

    @media screen and (max-width: 500px){
      display:none;
    }
  }
  .header-4 {
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .h4-section-1{
      margin-bottom: 50px;
      padding: 1em 200px;
      .title-h4s1{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 64px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #122541;
        margin-bottom: 20px;
      }
      .desc-h4s1{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #4A4A4A;
      }
    }
    .h4-section-2{
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 10px;
      padding: 2em 180px;
      .frame-detail-h4s2{
        .title-h4s2{
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 52px;
          letter-spacing: 0.3px;
          color: #122541;
          margin-bottom: 20px;
        }
        .desc-h4s2{
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 36px;
          letter-spacing: 0.3px;
          color: #4A4A4A;
          text-align: left;
          padding-right: 20px;
        }
      }
      .left{
        margin-right: 50px;
      }
      .right{
        margin-left: 50px;
      }
      .frame-image-h4s2 {
        // padding: 10px 50px;
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 0.5em 20px;
      .h4-section-1 {
        padding: 1em 20px;
        margin-bottom: 20px;

        .title-h4s1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 36px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #122541;
          margin-bottom: 20px;
        }

        .desc-h4s1 {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #4A4A4A;
        }
      }

      .h4-section-2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 20px;
        padding: 0em 20px;

        .frame-detail-h4s2 {
          width: 100%;
          margin-bottom: 10px;
          .title-h4s2 {
            text-align: left;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.3px;
            color: #122541;
            margin-bottom: 20px;
          }

          .desc-h4s2 {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.3px;
            color: #4A4A4A;
            text-align: left;
            padding-right: 0px;
          }
        }

        .left {
          margin-right: 0px;
        }

        .right {
          margin-left: 0px;
        }

        .frame-image-h4s2 {
          margin-top: 10px;
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          .img-h4s2{
            height: 250px;
          }
          // padding: 10px 50px;
        }
      }
      .reverse-flex {
        flex-direction: column-reverse !important;
      }
    }
  }
  .header-5 {
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    padding: 20px 10%;
    margin-bottom: 80px;
    .title-h5{
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #122541;
      margin-bottom: 50px;
    }
    .content-h5{
      display: flex;
      .content-left-h5{
        display: flex;
        flex-direction: column;
        width: 45%;
        .item-left-h5 {
          cursor: pointer;
          padding: 25px 30px;
          padding-right: 80px;
          span{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.3px;
            color: #122541;
          }
          border-bottom: 1px solid #DDDDDD;
        }
        .active {
          cursor: pointer;
          padding: 25px 30px;
          padding-right: 80px;
          background: #17A84B;
          box-shadow: 0px 20px 40px rgba(4, 107, 26, 0.15);
          border-radius: 16px;
          span{
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.3px;
            color: #FFFFFF;
          }
        }
        .arrow {
          display: none !important;
        }
      }
      .content-right-h5{
        margin-left: 50px;
        width: 55%;
        display: flex;
        flex-direction: column;
        .frame-right-img-h5{
          margin-bottom: 20px;
          height: 350px;
          img{
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
        .frame-right-title-h5{
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0.3px;
          color: #122541;
          margin-bottom: 10px;
        }
        .frame-right-desc-h5{
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 36px;
          letter-spacing: 0.3px;
          color: #4A4A4A;

          .frame-right-desc-h5-content {
            margin-top: 20px;
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 20px 20px;
      margin-bottom: 80px;

      .title-h5 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #122541;
        margin-bottom: 20px;
      }

      .content-h5 {
        display: flex;
        flex-direction: column-reverse;
        .content-left-h5 {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          width: 100%;

          .item-left-h5 {
            margin-left: 10px;
            margin-top: 12px;
            cursor: pointer;
            padding: 0px;
            width: 8px;
            height: 8px;
            padding-right: 0px;
            border-bottom: 0px solid transparent;
            background: #077FA2;
            border-radius: 50%;
            opacity: 0.1;
            margin-right: 10px;
            span{
              display: none;
            }
          }

          .active {
            cursor: pointer;
            padding: 0px;
            border-bottom: 0px solid transparent;
            background: #077FA2;
            border-radius: 50%;
            box-shadow: 0px 20px 40px rgba(4, 107, 26, 0.15);
            border-radius: 16px;
            opacity: 1;
            span{
              display: none;
            }
          }

          .arrow {
            display: inline-block !important;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            opacity: 0.2;
            background: #FFFFFF;
            box-shadow: 0px 20px 40px rgba(48, 93, 255, 0.08);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            border: none;

            &:active {
              outline: none;
            }

            &:focus {
              outline: none;
            }
          }

          .arrow-active {
            opacity: 1;
          }
        }

        .content-right-h5 {
          margin-left: 0px;
          width: 100%;
          display: flex;
          flex-direction: column;
          .frame-right-img-h5 {
            margin-bottom: 10px;
            height: 250px;
            border-radius: 16px;
            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .frame-right-title-h5 {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.3px;
            color: #122541;
            margin-top: 15px;
            margin-bottom: 10px;
          }

          .frame-right-desc-h5 {
            height: 200px;
            overflow: auto;
            overflow-x: hidden;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.3px;
            color: #4A4A4A;
          }
        }
      }
    }
  }
  .header-6{
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
    .koma-founders{
      position: absolute;
      right: 110px;
      margin-top: 30px;
    }
    .title-h6{
      // margin-top: 0px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #122541;
    }
    .detail-founders-h6{
        padding: 50px 200px;
        padding-top: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .frame-image{
          margin-right: 40px;
          mix-blend-mode: multiply;
          .profil-image{
            z-index: -1;
            img{
              // height: 100%;
              width: 100%;
              height: 350px;
              object-fit: cover;
            }
          }
          .frame-detail-profil{
            z-index: 999;
            margin-top: -30px;
            // margin-left: 50px;
            .img-frame-title{
              // z-index: -1;
            }
            .frame-title-image{
              z-index: 999;
              margin-top: -70px;
              margin-left: 20px;
              width: 100%;
              // background: linear-gradient(144.88deg, #077FA2 -7.33%, #17A84B 121.92%);
              // background: url('/assets/bg-founders.svg');
              display: flex;
              flex-direction: column;
              .name{
                font-family: Poppins;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.3px;
                color: #FFFFFF;
                margin-bottom: 10px;
              }
              .title{
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 0.3px;
                color: #FFFFFF;
              }
            }
          }
        }
        .frame-desc{
          width: 60%;
          max-height: 350px;
          overflow-y: scroll;
          background: rgba(255, 255, 255, 0.8);
          backdrop-filter: blur(40px);
          padding: 10px 25px;
          padding-bottom: 30px;
          -webkit-box-shadow: 50px 50px 100px -20px rgba(15, 29, 78, 0.28);
          box-shadow: 50px 50px 100px -20px rgba(15, 29, 78, 0.28);
          // &::-webkit-scrollbar {
          //   display: none;
          // }
          // -ms-overflow-style: none;
          // scrollbar-width: none;
          overflow-y: scroll;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background: #7BB0FF;
            border-radius: 50px;
          }
          p{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 180%;
            letter-spacing: 0.3px;
            color: #121212;
          }
        }
    }
    .list-founders-h6{
      display: flex;
      margin-top: 0px;
      .item-founders{
        cursor: pointer;
        padding: 10px 30px;
        padding-top: 25px;
        min-width: 175px;
        margin-right: 10px;
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        .img-founders{
          width: 66.89px;
          // height: 100%;
          height: 66.89px;
        }
        .frame-detail-f{
          overflow: auto;
          max-height: 100px;
          width: 164px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .name{
            margin-top: 10px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #122541;
          }
          .title{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #17A84B;
          }
        }
      }
      .active-founders{
        background: rgba(255, 255, 255, 0.6);
        border: 2px solid #17A84B;
        backdrop-filter: blur(40px);
        border-radius: 16px;
      }
      .last-item-founders{
        margin-right: 0px;
      }
      .arrow {
        display: none !important;
      }
    }
    @media screen and (max-width: 1024px) {
      background: #F9FCFF;
      .koma-founders{
        display: none;
        margin-top: 0;
      }
      .title-h6 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #122541;
        margin-bottom: 20px;
      }
      padding: 20px 20px;
      .detail-founders-h6 {
        background: #FEFFFE;
        box-shadow: 60px 60px 60px rgba(18, 123, 182, 0.08);
        border-radius: 16px;

        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .frame-image {
          background: #FEFFFE;
          border-radius: 16px;
          margin-right: 0px;
          mix-blend-mode: multiply;

          .profil-image {
            z-index: -1;

            img {
              margin-left: 10px;
              width: auto;
              height: 250px;
            }
          }

          .frame-detail-profil {
            z-index: 999;
            margin-top: -30px;
            margin-left: 10px;

            // margin-left: 50px;
            .img-frame-title {
              // z-index: -1;
              width: 279px !important;
              height: 52px !important;
            }

            .frame-title-image {
              z-index: 999;
              margin-top: -40px;
              margin-left: 20px;
              width: 100%;
              // background: linear-gradient(144.88deg, #077FA2 -7.33%, #17A84B 121.92%);
              // background: url('/assets/bg-founders.svg');
              display: flex;
              flex-direction: column;

              .name {
                font-family: Poppins;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.3px;
                color: #FFFFFF;
                margin-bottom: 0px;
              }

              .title {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.3px;
                color: #FFFFFF;
              }
            }
          }
        }
        
        .frame-desc {
          width: 100%;
          margin-top: 40px;
          max-height: 250px !important;
          overflow-y: scroll;
          background: #FEFFFE;
          box-shadow: 10px 10px 5px 0px rgba(245, 242, 242, 0.75);
          -webkit-box-shadow: 10px 10px 5px 0px rgba(245, 242, 242, 0.75);
          -moz-box-shadow: 10px 10px 5px 0px rgba(245, 242, 242, 0.75);
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar-track{
            background: #E0EDFF;
          }
          &::-webkit-scrollbar-thumb {
            background: #7BB0FF;
            border-radius: 50px;
          }

          p {
            background: #FEFFFE;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 180%;
            letter-spacing: 0.3px;
            color: #121212;
          }
        }
      }

      .list-founders-h6 {
        display: flex;
        margin-top: 30px;

        .item-founders {
          margin-top: 10px;
          cursor: pointer;
          padding: 0px;
          padding-top: 0;
          min-width: auto;
          margin-left: 10px;
          width: 8px;
          height: 8px;
          display: flex;
          background: #077FA2;
          border-radius: 50%;
          opacity: 0.1;
          // justify-content: center;
          align-items: center;
          flex-direction: column;

          .img-founders {
            display: none;
          }

          .frame-detail-f {
            display: none;
          }
        }

        .active-founders {
          background: #077FA2;
          border-radius: 50%;
          opacity: 1;
        }

        .last-item-founders {
          margin-right: 0px;
        }
        .arrow {
          display: inline-block !important;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          opacity: 0.2;
          background: #FFFFFF;
          box-shadow: 0px 20px 40px rgba(48, 93, 255, 0.08);
          transform: matrix(-1, 0, 0, 1, 0, 0);
          border: none;
          text-align: center;
          vertical-align: center;
          &:active {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
        .right {
          img {
            margin-right: 3px;
          }
        }
        .left {
          img {
            margin-left: 3px;
          }
        }

        .arrow-active {
          opacity: 1;
        }
      }
    }
  }
  .header-7 {
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    margin-top: 100px;
    padding-bottom: 60px;
    .frame-title-h7{
      .title-h7{
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 64px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #122541;
      }
      .sub-title-h7{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #4A4A4A;
      }
    }
    .frame-bg{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -150px;
      .bg-drop{
        background: rgba(18, 123, 182, 0.1);
        filter: blur(150px);
        width: 60%;
        height: 210px;
      }
    }
    .list-community{
      margin-top: 100px;
      padding: 20px 100px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .item-cummunity{
        cursor: pointer;
        width: 252px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        background: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(30px);
        border-radius: 20px;
        .img-cummunity{

        }
        .item-title{
          height: 50px;
          overflow: hidden;
          margin-top: 20px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #0A66C2;
        }
        .item-sub-title {
          height: 60px;
          overflow: hidden;
          margin-top: 40px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #0A66C2;
        }
        &:hover{
          text-decoration: none;
          background: rgba(255, 255, 255, 0.6);
          border: 2px solid #178CF8;
          backdrop-filter: blur(30px);
          border-radius: 20px;
        }
      }
      .active-community{
        background: rgba(255, 255, 255, 0.6);
        border: 2px solid #178CF8;
        backdrop-filter: blur(30px);
        border-radius: 20px;
      }
    }
    @media screen and (max-width: 1024px) {
      margin-top: 20px;
      padding-bottom: 30px;
      .frame-title-h7 {
        padding: 20px 80px;
        .title-h7 {
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 36px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #122541;
          margin-bottom: 10px;
        }

        .sub-title-h7 {
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.3px;
          color: #4A4A4A;
        }
      }

      .frame-bg {
        display: none;
      }

      .list-community {
        margin-top: 10px;
        padding: 20px 100px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;

        .item-cummunity {
          cursor: pointer;
          width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 20px 20px;
          background: rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(30px);
          border-radius: 20px;
          margin-bottom: 20px;
          .img-cummunity {
            height: 48px;
            margin-right: 20px;
          }

          .item-title {
            width: 100%;
            height: auto;
            overflow: hidden;
            margin-top: 0px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #0A66C2;
          }

          .item-sub-title {
            display: none;
            height: 60px;
            overflow: hidden;
            margin-top: 40px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.3px;
            color: #0A66C2;
          }
        }

        .active-community {
          background: rgba(255, 255, 255, 0.6);
          border: 2px solid #178CF8;
          backdrop-filter: blur(30px);
          border-radius: 20px;
        }
      }
    }
  }
  .header-8 {
    display: flex;
    // justify-content: space-around;
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    padding: 0px 15%;
    padding-bottom: 70px;
    .frame-left{
      width: 30%;
      .frame-detail-h8{
        .list-sub-title{
          span{
            border-radius: 2000px;
            padding: 5px 10px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #36B37E;
          }
          .success{
            background: #36b37f2a;
          }
        }
        .title-h8 {
          margin-top: 10px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 48px;
          line-height: 64px;
          letter-spacing: 0.3px;
          color: #122541;
        }
        .sub-title-h8{
          margin-top: 20px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 0.3px;
          color: #4A4A4A;
        }
      }
      .pagination-h8{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 40px;
        div{
          margin-right: 10px;
        }
        img{
          height: 56px;
        }
        .left{}
        .right{}
      }
    }
    .frame-right{
      margin-left: 50px;
      width: 65%;
      .item-h8 {
        .frame-img {
          z-index: 1;

          img {
            width: 265px;
            height: 300px;
            object-fit: cover;
          }
        }

        .frame-detail {
          position: relative;
          z-index: 2;
          margin-left: 20px;
          margin-top: -40px;
          // background: red !important;
          background: #ffff !important;
          width: 288px;
          height: 178px;
          .frame-content{
            position: absolute;
            height: 200px;
            z-index: 5;
            display: flex;
            flex-direction: column;
            padding: 20px 20px;
            padding-bottom: 10px;
            .title-item {
              cursor: pointer;
              font-family: Poppins;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: 0.3px;
              color: #122541;
              margin-bottom: 5px;
              overflow: scroll;
              overflow-x: hidden;
              height: 120px;
              &::-webkit-scrollbar {
                width: 4px;
              }

              &::-webkit-scrollbar-track {
                background: #fff;
              }

              &::-webkit-scrollbar-thumb {
                background: #7BB0FF;
                border-radius: 50px;
              }
            }
            .sub-title-item {
              padding-bottom: 10px;

              .publisher {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.3px;
                color: #0A66C2;
                margin-right: 10px;
              }

              .date {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.3px;
                color: #777777;
              }
            }
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 0;
      padding-right: 25px;
      padding-bottom: 50px;
      flex-direction: column;
      .frame-left{
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        .frame-detail-h8 {
          .list-sub-title {
            span {
              border-radius: 2000px;
              padding: 5px 10px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #36B37E;
            }

            .success {
              background: #36b37f2a;
            }
          }

          .title-h8 {
            margin-top: 20px;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.3px;
            color: #122541;
          }

          .sub-title-h8 {
            padding: 10px 60px;
            margin-top: 0px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.3px;
            color: #4A4A4A;
          }
        }

        .pagination-h8 {
          display: none;
        }
      }
      .frame-right {
        margin-left: 0px;
        width: 100%;
        .item-h8 {
          .frame-img {
            z-index: 1;

            img {
              width: 265px;
              height: 274px;
              border-radius: 12px;
              object-fit: cover;
            }
          }

          .frame-detail {
            position: relative;
            z-index: 2;
            margin-left: 40px;
            margin-top: -40px;
            // background: red !important;
            background: #ffff !important;
            width: 276px;
            height: 178px;

            .frame-content {
              position: absolute;
              height: 200px;
              z-index: 5;
              display: flex;
              flex-direction: column;
              padding: 20px 20px;
              padding-bottom: 10px;

              .title-item {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.3px;
                color: #122541;
              }

              .sub-title-item {
                padding-bottom: 10px;

                .publisher {
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.3px;
                  color: #0A66C2;
                  margin-right: 10px;
                }

                .date {
                  font-family: Poppins;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.3px;
                  color: #777777;
                }
              }
            }
          }
        }
      }
    }
  }
  .header-9{
    justify-content: space-around;
    background: #F9FCFF;
    height: 100%;
    width: 100%;
    padding-bottom: 70px;
    // padding-bottom: 45%;
    .title-h9{
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 64px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #122541;
      margin-bottom: 20px;
    }
    .frame-faq {
      padding: 20px 200px;
    }
    .frame-img-bg-1{
      z-index: -1;
      position: relative;
      height: 438px;
      width: 100%;
      // background: rgba(18, 123, 182, 0.08);
      // filter: blur(150px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      // background-color: red;
      margin-top: -40%;
    }
    .frame-img-bg-2 {
      z-index: -1;
      margin-top: -60%;
      // background-color: blue;
      position: relative;
      height: 492px;
      width: 100%;
      // background: rgba(18, 123, 182, 0.08);
      // filter: blur(150px);
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
    @media screen and (max-width: 1024px) {
      padding-bottom: 30px;
      .title-h9 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #122541;
        margin-bottom: 10px;
      }

      .frame-faq {
        padding: 20px 20px;
      }

      .frame-img-bg-1 {
        display: none !important;
        visibility: hidden;
        z-index: -1;
        position: relative;
        height: 438px;
        width: 100%;
        // background: rgba(18, 123, 182, 0.08);
        // filter: blur(150px);
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        // background-color: red;
        margin-top: -40%;
      }

      .frame-img-bg-2 {
        display: none !important;
      }
    }
  }
  .tkonews{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: linear-gradient(134.46deg, #077FA2 7.56%, #17A84B 125.58%);
    .img-tkonews{}
    .desc-tkonews{
      width: 30%;
      text-align: left;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.008em;
      color: #FFFFFF;
    }
    .form-input{
      width: 30%;
      display: flex;
      .frame-input{
        background: #FFFFFF;
        border-radius: 8px;
        width: 363px;
        height: 72px;
        background: #FFFFFF;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          padding-left: 25px;
          border: none;
          outline: none;
          width: 100%;
          &::placeholder{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #ADADAD;
          }
          &:focus{
            border: none;
            outline: none;
          }
          &:active{
            border: none;
            outline: none;
          }
        }
      }
      .button-news{
        margin-left: 15px;
        width: 72px;
        height: 72px;
        background: #01DE4C;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        border: none;

        &:focus {
          border: none;
          outline: none;
        }

        &:active {
          border: none;
          outline: none;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 40px 20px;
      .img-tkonews{
        display: none;
      }
      .desc-tkonews {
        width: 100%;
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.008em;
        color: #FFFFFF;
        margin-bottom: 30px;
      }

      .form-input {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .frame-input {
          background: #FFFFFF;
          border-radius: 8px;
          width: 327px;
          height: 58px;
          background: #FFFFFF;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          input {
            padding-left: 25px;
            border: none;
            outline: none;
            width: 100%;

            &::placeholder {
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #ADADAD;
            }

            &:focus {
              border: none;
              outline: none;
            }

            &:active {
              border: none;
              outline: none;
            }
          }
        }

        .button-news {
          margin-left: 0px;
          width: 70px;
          height: 58px;
          background: #01DE4C;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          border: none;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          &:focus {
            border: none;
            outline: none;
          }

          &:active {
            border: none;
            outline: none;
          }
        }
      }
    }
  }
  .footer{
    height: 100%;
    width: 100%;
    background: #0D192C;
    padding-top: 50px;
    .white-doc{
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      img{
        width: 55%;
        height: 345px;
      }
      .frame-white-doc{
        width: 45%;
        .title-white-doc{
          font-family: Poppins;
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 64px;
          letter-spacing: 0.3px;
          color: #EFEFEF;
          margin-bottom: 20px;
        }
        .sub-title-white-doc{
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 0.3px;
          color: #EFEFEF;
          margin-bottom: 60px;
        }
      }
      .list-button-wd{
        .btn-lite{
          padding: 10px 20px;
          background: linear-gradient(144.88deg, #077FA2 -7.33%, #17A84B 121.92%);
          border-radius: 12px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 36px;
          letter-spacing: 0.3px;
          color: #FFFFFF;

        }        
        .btn-white {
          margin-left: 10px;
          padding: 10px 20px;
          background: #0D192C;
          border: 1px solid #B4B4B4;
          box-sizing: border-box;
          border-radius: 12px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 36px;
          letter-spacing: 0.3px;
          color: #B4B4B4;
        }
      }
    }
    .footer-frame {
      margin-top: 40px;
      padding: 40px 80px;
      display: flex;
      .footer-menu{
        width: 65%;
        display: flex;
        .item-footer{
          width: 24%;
          display: flex;
          margin-right: 40px;
          &:nth-last-child(n){
            margin-right: 0px;
          }
          // min-width: 190px;
          flex-direction: column;
          .title-foot{
            span{
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 35px;
              color: #FFFFFF;
              margin-bottom: 10px;
            }
            img{
              display: none;
            }
          }
          .item-foot{
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 35px;
            color: #B0B0B0;
          }
        }
      }
      .footer-stayupdate{
        display: flex;
        .member-of{
          margin-right: 30px;
          .title-member{
            margin-bottom: 20px;
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 35px;
            color: #FFFFFF;
          }
          img{

          }
        }
        .register-of{
          .title-register {
            margin-bottom: 20px;
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 35px;
            color: #FFFFFF;
          }
          .list-register{
            display: flex;
            img{
              margin-right: 10px;
              height: 50px;
            }
          }
        }
      }
    }
    .footer-disc{
      padding: 40px 80px;
      padding-bottom: 0px;
      .title-disc{
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 35px;
        color: #FFFFFF;
      } 
      .desc-disc {
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 35px;
        color: #B0B0B0;
        max-width: 70%;
      }
    }
    .footer-bot{
      padding: 40px 80px;
      display: flex;
      justify-content: space-between;
      .tag{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
      }
      .sosmed{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        a{
          margin-right: 20px;
          &:nth-last-child(n){
            margin-right: 0px;
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .white-doc {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        flex-direction: column;

        img {
          width: 100%;
          height: 201px;
        }

        .frame-white-doc {
          width: 100%;
          padding: 20px;
          .title-white-doc {
            margin-top: 10px;
            text-align: left;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.3px;
            color: #EFEFEF;
            margin-bottom: 20px;
          }

          .sub-title-white-doc {
            text-align: left;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.3px;
            color: #EFEFEF;
            margin-bottom: 60px;
          }
        }

        .list-button-wd {
          display: flex;
          flex-direction: column;
          .btn-lite {
            width: 100%;
            padding: 20px 20px;
            background: linear-gradient(144.88deg, #077FA2 -7.33%, #17A84B 121.92%);
            border-radius: 12px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 36px;
            letter-spacing: 0.3px;
            color: #FFFFFF;
            margin-bottom: 20px;
          }

          .btn-white {
            width: 100%;
            margin-left: 0px;
            padding: 20px 20px;
            background: #0D192C;
            border: 1px solid #B4B4B4;
            box-sizing: border-box;
            border-radius: 12px;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 36px;
            letter-spacing: 0.3px;
            color: #B4B4B4;
          }
        }
      }

      .footer-frame {
        margin-top: 10px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        .footer-menu {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .item-footer {
            width: 100%;
            display: flex;
            margin-right: 0px;
            margin-bottom: 20px;

            &:nth-last-child(n) {
              margin-right: 0px;
              margin-bottom: 0px;
            }

            // min-width: 190px;
            flex-direction: column;

            .title-foot {
              display: flex;
              align-content: center;
              justify-content: space-between;
              cursor: 100%;
              span{
                font-family: Poppins;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 35px;
                color: #FFFFFF;
                margin-bottom: 10px;
              }
              img{
                display: inline-block;
              }
            }

            .item-foot {
              display: none;
              font-family: Poppins;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 35px;
              color: #B0B0B0;
              margin-bottom: 10px;
            }
            .without-margin{
              margin-bottom: 0px;
            }
            .foot-active{
              display: inline-block;
            }
          }
        }

        .footer-stayupdate {
          display: flex;
          flex-direction: column;
          .member-of {
            margin-right: 0px;
            .title-member {
              margin-bottom: 20px;
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 35px;
              color: #FFFFFF;
            }

            img {
              margin-bottom: 20px;
            }
          }

          .register-of {
            .title-register {
              margin-bottom: 20px;
              font-family: Poppins;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 35px;
              color: #FFFFFF;
            }

            .list-register {
              display: flex;

              img {
                margin-right: 10px;
                height: 50px;
              }
            }
          }
        }
      }

      .footer-disc {
        padding: 10px 20px;
        padding-bottom: 0px;

        .title-disc {
          font-family: Poppins;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 35px;
          color: #FFFFFF;
        }

        .desc-disc {
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 35px;
          color: #B0B0B0;
          max-width: 70%;
        }
      }

      .footer-bot {
        padding: 40px 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        .tag {
          margin-top: 20px;
          font-family: Poppins;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #FFFFFF;
        }

        .sosmed {
          a {
            margin-right: 10px;

            &:nth-last-child(n) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  // .header-6{
  //   display: none !important;
  // }
}

.img-slideshow{
  padding: 40px 5px;
  @media screen and (max-width: 1024px){
    padding: 20px 5px;
    margin-bottom: 10px;
  }
}

.card-faq {
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(40px);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px 30px;

  .frame-faq-title {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 36px;
      letter-spacing: 0.3px;
      color: #000000;
    }
  }

  .frame-faq-desc {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 31px;
    color: #5A5A5A;
  }
  @media screen and (max-width: 1024px) {
    padding: 20px 20px;
    .frame-faq-title {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.3px;
        color: #000000;
      }
    }

    .frame-faq-desc {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 180%;
      color: #5A5A5A;
    }
  }
}